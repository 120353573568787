import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const PaystackModal = ({ ShowModal, setShowModal, URL, title }) => {
	const [isLoading, setIsLoading] = useState(true); // Track loading state

	const handleIframeLoad = () => {
		setIsLoading(false); // Hide spinner when iframe loads
	};
	return (
		<Modal
			show={ShowModal}
			onHide={() => {
				setShowModal(false);
			}}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title className="text-capitalize">
					{isLoading && (
						<span className="spinner-border text-primary h4"></span>
					)}
					{title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row justify-content-center" style={{ height: "80vh" }}>
					<iframe
						onLoad={handleIframeLoad}
						src={URL}
						title="Embedded URL"
						className="iframe-content"
					/>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default PaystackModal;
