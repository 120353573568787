import React, { useState, useEffect } from "react";
import { PaymentUrl } from "../../../Network/Urls";
import swal from "sweetalert";
import PaystackModal from "./PaystackModal";
import { useSelector } from "react-redux";
import { SocketJoinRoom, socket } from "../../../Utils/SockectConnect";

const PaystackPay = ({
	amountUSD,
	kenyanRate,
	localRate,
	type,
	softwareId,
	localCurrency,
	email,
	from,
	setSubs,
}) => {
	useEffect(() => {
		ConnectRoom();
		ReceiveMessages();
	}, []);
	const User = useSelector((state) => state.auth.user);
	const [Code, setCode] = useState({});
	const [ShowModal, setShowModal] = useState(false);
	const [URL, setURL] = useState(false);

	const ConnectRoom = () => {
		SocketJoinRoom({
			clientId: softwareId,
		});
	};

	const ReceiveMessages = () => {
		setShowModal(false);

		socket.on("message", (message) => {
			if (from === "software") {
				setCode(message.dataValues);
				swal({
					title: "Activation Successfull",
					text: `Code is ${message.dataValues.code}`,
					icon: "success",
					timer: 30000,
				});
				setSubs([{ ...message.dataValues }]);
			} else {
				//navigate(`/${inst.name}`);
			}
		});
	};

	const initializePayment = async () => {
		try {
			const response = await fetch(`${PaymentUrl}/payment/initialize`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: User.email || email,
					amount: parseInt(amountUSD * kenyanRate) + 1,
					periodType: type,
					instType: "business",
					softwareId,
				}),
			});

			const data = await response.json();

			if (!response.ok) {
				throw new Error(data.error);
			}

			setURL(data.data.authorization_url);
			setShowModal(true);
		} catch (error) {
			swal({
				title: "Error In Payment",
				text: "Please Try Again",
				icon: "warning",
				timer: 5000,
			});
		}
	};

	return (
		<div>
			<button
				className="btn btn-sm rounded-pill btn-primary px-3"
				onClick={initializePayment}
			>
				Pay
			</button>
			<PaystackModal
				ShowModal={ShowModal}
				setShowModal={setShowModal}
				URL={URL}
				title={
					Code.code && from === "software" ? (
						<em>
							Activation Code is <strong>{Code.code}</strong>
						</em>
					) : (
						`Pay ${type} ${localCurrency} ${
							parseInt(amountUSD * localRate) + 1
						} equals KES ${parseInt(amountUSD * kenyanRate) + 1}`
					)
				}
				setSubs={setSubs}
			></PaystackModal>
		</div>
	);
};

export default PaystackPay;
